export const commonPageEventActions = {
    searchSection: {
        searchBox: {
            SEARCH: 'click_search',
            CLICK_SEARCH: 'searchbox_click_search',
        },
    },
    breadcrumbSection: {
        BREADCRUMB: 'click_breadcrumb',
    },
}

export const landingPageEventActions = {
    searchSection: {
        searchBox: {
            ARRIVAL_DATE: 'add_arrvial',
            DEPARTURE_DATE: 'add_departure',
            LOCATION: 'add_destination',
            PERSONS_COUNT: 'click_pers',
        },
    },
    headerSection: {
        RENTAL: 'click_rental',
        WISHLIST: 'click_wishlist',
    },
    newsLetterSection: {
        REGISTER: 'click_newsletter_subscription',
    },
    listingCardSection: {
        TOP_INSERAT: 'click_topinserat',
        WISHLIST_POP_UP: 'popupWishlist',
    },
    stickyFooter: {
        VIEW_ALL_RECOMMENDATION_CLICKED: 'view_all_recommendations_clicked',
    },
}

export const searchResultListingsPageEventActions = {
    listViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    mapViewPageSpecific: {
        mapSection: {
            MAP: 'do_mapaction',
        },
    },
    LISTINGS: 'click_resultlist_snippet',
    tileViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    filterSection: {
        FILTER_CLICK: 'result_filter_click_filteroption',
    },
}

export const eventPageActions = {
    landingPageActions: {
        SEARCH_BUTTON: 'click_bk_search',
        MOST_POPULAR_DESTINATION: 'click_bk_mostpopular_by_dest',
        MOST_POPULAR_CATEGORY: 'click_bk_mostpopular',
    },
    searchResultPageActions: {
        SEARCH_RESULT_SNIPPET: 'click_bk_events_srl_snippet',
    },
    detailPageActions: {
        BOOKING_BUTTON: 'click_bk_events_booking',
    },
}

export const pdpPageActions = {
    ENQUIRY_BUTTON_CLICKED: 'enquiry_button_clicked',
    BOOK_NOW_BUTTON_CLICKED: 'book_now_button_clicked',
    BINDING_INQUIRY_BUTTON_CLICKED: 'binding_enquiry_button_clicked',
    CONTACT_ENTER_DETAILS_BUTTON_CLICKED: 'contact_details_button_clicked',
    PDP_PAGE_OPENED: 'pdp_page_opened',
    SHOW_MORE_REVIEWS_BUTTON_CLICKED: 'show_more_reviews_clicked',
    HIDE_REVIEWS_CLICKED: 'hide_reviews_clicked',
    GIVE_REVIEW_CLICKED: 'give_review_clicked',
    DATE_PICKER_START_DATE_CHANGED: 'date_picker_start_date_changed',
    DATE_PICKER_END_DATE_CHANGED: 'date_picker_end_date_changed',
    DATES_SELECTED: 'dates_selected',
    ADULTS_COUNT_CHANGED: 'adults_count_changed',
    CHILDREN_COUNT_CHANGED: 'children_count_changed',
}
