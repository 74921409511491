import React, { CSSProperties, FC, Fragment, useMemo } from 'react'
import Head from 'next/head'

import config from '../../common/config'
import HeadTransport, { HrefLangTransport } from '../../transports/common/head-transport'
import { useTranslation } from '../../hooks/locale'
import { getBasePath } from '../../utils/miscellaneous-utils'

type Props = {
    head: HeadTransport
    eventApplicationName?: boolean
}

const _getHrefLangItems = (hrefLangObject: HrefLangTransport | null | undefined) =>
    hrefLangObject
        ? Object.entries(hrefLangObject)
              .filter(([_, href]) => href !== null)
              .map(([lang, href]) => <link key={lang} rel="alternate" href={href} hrefLang={lang} />)
        : []

const _getTagManagerHTML = (containerId: string) => ({
    __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        if (localStorage.getItem('consentMode') === null) {
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied',
                'functionality_storage': 'denied',
                'personalization_storage': 'denied',
                'security_storage': 'denied',
            });
        } else {
            gtag('consent', 'default', JSON.parse(localStorage.getItem('consentMode')));
        }

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${containerId}');`,
})

const _style: CSSProperties = { display: 'none', visibility: 'hidden' }

const Page: FC<Props> = ({
    head: { title, copyright, socialMediaImage, googleSiteVerification, description, locale, hreflang, canonical },
    eventApplicationName,
    children,
}) => {
    const _hrefLangItems = useMemo(() => _getHrefLangItems(hreflang), [hreflang])
    const _tagManagerHtml = useMemo(() => _getTagManagerHTML(config.gtmContainerId), [])
    const { t } = useTranslation()

    return (
        <Fragment>
            <Head>
                <title>{title}</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0"
                />
                <meta name="msvalidate.01" content="7295756D5DACC26A911F2500200504FC" />
                {!!googleSiteVerification && <meta name="google-site-verification" content={googleSiteVerification} />}
                {!!copyright && <meta name="copyright" content={copyright} />}
                {!!locale && <meta name="content-language" content={locale} />}
                {!!description && <meta name="description" content={description} />}
                {!!canonical && !!canonical.url && (
                    <Fragment>
                        <meta property="og:title" content={title || ''} />
                        <meta property="og:description" content={description || ''} />
                        <meta property="og:site_name" content={t('companyShort')} />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={canonical.url} />
                        <meta property="og:locale" content={locale || ''} />

                        {/*Twitter*/}
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:title" content={title || ''} />
                        <meta name="twitter:description" content={description || ''} />
                        <meta name="twitter:url" content={canonical.url} />
                        <meta name="twitter:site" content="@traumfewo" />
                        {socialMediaImage && <meta name="twitter:image" content={socialMediaImage} />}
                    </Fragment>
                )}
                {locale === 'de-AT' && (
                    <meta name="audisto-confirm" content="c13def876fddd445ca7cc039b06efe84e2ae1679" />
                )}
                <meta
                    name="robots"
                    content={
                        (canonical?.noIndex ? 'noindex, ' : 'index, ') + (canonical?.noFollow ? 'nofollow' : 'follow')
                    }
                />
                {!!canonical?.url && <link rel="canonical" href={canonical.url} />}
                {!!canonical?.relNext && <link rel="next" href={canonical.relNext} />}
                {!!canonical?.relPrev && <link rel="prev" href={canonical.relPrev} />}
                {_hrefLangItems}
                <meta
                    name="application-name"
                    content={
                        !!eventApplicationName
                            ? 'Traum-Ferienwohnungen - Aktivitäten, Touren & Attraktionen'
                            : 'Traum-Ferienwohnungen - Ferien mit Herz'
                    }
                />
                <meta name="p:domain_verify" content="4bd5af6331242b3a140bbcdc4391e5c6" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-title" content={t('companyShorter')} />
                <link
                    rel="shortcut icon"
                    type="image/x-icon"
                    href={getBasePath('/assets/images/favicon/favicon.ico')}
                />
                <link
                    rel="icon"
                    type="image/vnd.microsoft.icon"
                    sizes="16x16 32x32 48x48"
                    href={getBasePath('/assets/images/favicon/favicon.ico')}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={getBasePath('/assets/images/favicon/favicon-16.png')}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={getBasePath('/assets/images/favicon/favicon-32.png')}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="76x76"
                    href={getBasePath('/assets/images/favicon/favicon-76.png')}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href={getBasePath('/assets/images/favicon/favicon-120.png')}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href={getBasePath('/assets/images/favicon/favicon-152.png')}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={getBasePath('/assets/images/favicon/favicon-180.png')}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="192x192"
                    href={getBasePath('/assets/images/favicon/favicon-180.png')}
                />
                <link
                    rel="mask-icon"
                    href={getBasePath('/assets/images/favicon/safari-pinned-tab.svg')}
                    color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#FFFFFF" />
                <meta name="msapplication-TileImage" content={getBasePath('/assets/images/favicon/favicon-70.png')} />
                <meta name="msapplication-TileImage" content={getBasePath('/assets/images/favicon/favicon-144.png')} />
                <meta name="msapplication-TileImage" content={getBasePath('/assets/images/favicon/favicon-150.png')} />
                <meta name="theme-color" content="#ffffff" />
                {/* TODO: bring preconnect for images link according to domain */}
                <link rel="preconnect" href="//images.traum-ferienwohnungen.de" />
                <link rel="preconnect" href="//img2.traum-ferienwohnungen.de" />
                <link rel="preconnect" href="https://storage.googleapis.com" />
                {/* Google Tag Manager */}
                <script dangerouslySetInnerHTML={_tagManagerHtml} />
                {/* End Google Tag Manager */}
            </Head>

            {/* Google Tag Manager (noscript) */}
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${config.gtmContainerId}`}
                    height="0"
                    width="0"
                    style={_style}
                />
            </noscript>
            {/* End Google Tag Manager (noscript) */}

            {children}
        </Fragment>
    )
}

export default Page
